export const Color = {
    blue: '#225479',
    white: '#fff',
    black: '#000',
    gray: '#f0f0f0',
    darkGray: '#8C8C8C',
    red: '#F00'
} as const;

export { BASE_URL } from './api';
export { TOKEN } from './cookies';
