import React, {useState} from 'react';

import {useStores} from "@/shared/hooks";
import { handleYMClick } from '@/entities/Metrics';
import {IProduct} from "@/entities";

import * as Styles from "./AddToCartBtn.styles";

interface AddToCartBtnProps extends IProduct {
}

export const AddToCartBtn = (props: AddToCartBtnProps) => {
    const stores = useStores();
    const [text, setText] = useState('В корзину')
    function handleClick() {
        stores.cartStore.increaseProductsCount({...props});
        setText('Добавлено');
        handleYMClick("basket");
    }

    return (
        <Styles.Button
          onClick={handleClick}
        >
            <span>{text}</span>
            <span>+</span>
        </Styles.Button>
    );
};
