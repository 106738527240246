export const Paths = {
    main: '/',
    categories: '/categories',
    category: '/categories/:categoryId',
    contacts: '/contacts',
    subCategories: '/sub-categories',
    subCategory: '/sub-categories/:subCategoryId',
    manufacturers: '/manufacturers',
    manufacturer: '/manufacturers/:manufacturerId',
    products: '/products',
    product: '/products/:productId',
    aboutUs: '/about-us',
    shipping: '/shipping',
    payment: '/payment',
    cart: '/cart',
    privacyPolicy: '/privacy-policy',
    notFound: '/not-found',
    admin: '/admin',
    adminAuth: '/admin/auth',
    adminCategory: '/admin/categories',
    adminSubCategory: '/admin/:categoryId/:subCategoryId',
    adminProduct: '/admin/:categoryId/:subCategoryId/:productId',
    adminManufacturers: '/admin/manufacturers',
}
