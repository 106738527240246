import React, { ComponentPropsWithoutRef } from "react";

import { StyledInputMask } from "./PhoneMask.styles";

interface PhoneMaskProps extends ComponentPropsWithoutRef<"input"> {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PhoneMask = ({ value, onChange, ...restProps }: PhoneMaskProps) => {
  return (
    <StyledInputMask
      mask="+7(999) 999-99-99"
      alwaysShowMask={true}
      value={value}
      onChange={onChange}
      {...restProps}
    />
  );
};
