import { useMemo, useState } from "react";
import { observer } from "mobx-react";

import { useStores } from "@/shared/hooks";
import { ItemsGrid, PhoneMask } from "@/shared/components";
import { Paths } from "@/shared/routing";
import { MainLayout } from "@/shared/ui/Layouts";
import { IOrder, ProductCartCounter } from "@/entities";
import { handleYMClick } from "@/entities/Metrics";
import { CartProductCard } from "@/widgets/CartProductCard/CartProductCard";

import { processOrder } from "../api";

import * as Styles from "./CartPage.styles";

export const CartPage = observer(() => {
  const { cartStore } = useStores();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPrivacyAgreed, setPrivacyAgree] = useState(false);

  const productsInCart = cartStore.productsFromCart;

  let totalSum = productsInCart.reduce((acc, prev) => {
    if (!prev.inStock) {
      return acc;
    }

    return acc + prev.price * prev.count;
  }, 0);

  const isEveryProductInStock = useMemo(() => {
    return productsInCart.filter((p) => !p.inStock).length === 0;
  }, [productsInCart]);
  const isPhoneCorrect = useMemo(
    () => new RegExp(/\+7\(\d{3}\) \d{3}-\d{2}-\d{2}/).test(phoneNumber),
    [phoneNumber]
  );
  const isNameCorrect = useMemo(
    () => new RegExp(/^[A-Za-zА-Яа-яЁё\s'-]+$/).test(name) && name?.length > 2,
    [name]
  );
  const isEnoughProducts = useMemo(
    () => productsInCart.length > 0,
    [productsInCart]
  );

  const canSubmit = useMemo(
    () =>
      isPrivacyAgreed 
      && isEnoughProducts 
      && isPhoneCorrect 
      && isNameCorrect 
      && productsInCart.length > 0,
    [
      isPrivacyAgreed,
      isEnoughProducts,
      isPhoneCorrect,
      isNameCorrect,
      productsInCart,
    ]
  );

  const handleSubmit = function () {
    let orderInfo: IOrder = {
      products: productsInCart,
      name: name,
      phoneNumber: phoneNumber,
    };

    if (!isPrivacyAgreed) {
      alert("Необходимо согласиться с политикой конфиденциальности");
      return;
    }

    processOrder(orderInfo)
      .then(function (response) {
        if (response && +response?.status === 200) {
          alert("Вы успешно совершили заказ!");
          cartStore.deleteAllProducts();
          setName("");
          setPhoneNumber("");
          handleYMClick("order");
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const productsElements = productsInCart.map((p) => {
    return (
      <>
        <Styles.ProductWrapper>
          <CartProductCard product={p} key={p.id} />
          <ProductCartCounter product={p} />
        </Styles.ProductWrapper>
        <Styles.Line />
      </>
    );
  });

  return (
    <MainLayout>
      <Styles.CaptionWrapper isRight>
        <Styles.Link to={"/"}>Вернуться к покупкам</Styles.Link>
      </Styles.CaptionWrapper>
      <Styles.CaptionWrapper>
        <h1>Корзина</h1>
      </Styles.CaptionWrapper>
      {isEnoughProducts && (
        <Styles.ContactData>
          <Styles.InputWithLabelWrapper>
            <Styles.InputMaskWrapper>
              <Styles.Input
                type="text"
                onChange={(e) => setName(e.target.value)}
                placeholder="Имя"
                value={name}
              />
            </Styles.InputMaskWrapper>
            {!isNameCorrect && (
              <Styles.ErrorText>Пожалуйста, введите имя</Styles.ErrorText>
            )}
          </Styles.InputWithLabelWrapper>
          <Styles.InputWithLabelWrapper>
            <Styles.InputMaskWrapper>
              <PhoneMask
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
              />
            </Styles.InputMaskWrapper>
            {!isPhoneCorrect && (
              <Styles.ErrorText>
                Пожалуйста, введите корректный номер телефона
              </Styles.ErrorText>
            )}
          </Styles.InputWithLabelWrapper>
        </Styles.ContactData>
      )}
      <Styles.Flex>
        <ItemsGrid>{productsElements}</ItemsGrid>
        {isEnoughProducts && (
          <Styles.CartInfoMenu>
            <Styles.CartCaption>Ваш заказ</Styles.CartCaption>
            <Styles.OrderFieldsList>
              {productsInCart.map((p) => {
                return (
                  <Styles.OrderFieldsListItem>
                    {p.name}
                    <span>
                      {p.inStock
                        ? `${p.price * cartStore.getProductCount(p)} ₽`
                        : "По запросу"}{" "}
                    </span>
                  </Styles.OrderFieldsListItem>
                );
              })}
            </Styles.OrderFieldsList>
            <Styles.Summary>
              {"Итого:"}
              <span>{totalSum} ₽</span>
            </Styles.Summary>
            <Styles.PrivacyRow>
              <label htmlFor="privacyCheckbox">
                <Styles.LabelText>
                  Согласен с&nbsp;
                  <Styles.LabelLink to={Paths.privacyPolicy}>
                    политикой конфиденциальности
                  </Styles.LabelLink>
                </Styles.LabelText>
              </label>
              <Styles.Checkbox
                name="privacyCheckbox"
                type="checkbox"
                onChange={() => setPrivacyAgree((prev) => !prev)}
                checked={isPrivacyAgreed}
                isChecked={isPrivacyAgreed}
              />
            </Styles.PrivacyRow>
            <Styles.SubmitButton onClick={handleSubmit} disabled={!canSubmit}>
              Оформить
            </Styles.SubmitButton>
          </Styles.CartInfoMenu>
        )}
      </Styles.Flex>
    </MainLayout>
  );
});
