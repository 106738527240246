import React, {ComponentPropsWithoutRef} from 'react';
import { Color } from "@/shared/constants";

export const Edit = ({
                         width = 20, height = 20, color = Color.blue, ...restProps
                     }: ComponentPropsWithoutRef<'svg'>) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
         style={{cursor: 'pointer'}} {...restProps}>
        <path
            d="M9.50018 15.1343L15.6635 8.97093C14.6266 8.53799 13.6848 7.90544 12.8918 7.10926C12.0953 6.3161 11.4625 5.37407 11.0293 4.33676L4.86601 10.5001C4.38518 10.9809 4.14435 11.2218 3.93768 11.4868C3.6936 11.7995 3.48431 12.1379 3.31351 12.4959C3.16935 12.7993 3.06185 13.1226 2.84685 13.7676L1.71185 17.1701C1.65961 17.3259 1.65186 17.4932 1.68947 17.6531C1.72707 17.8131 1.80855 17.9593 1.92474 18.0755C2.04093 18.1917 2.18722 18.2732 2.34717 18.3108C2.50712 18.3484 2.67439 18.3407 2.83018 18.2884L6.23268 17.1534C6.87851 16.9384 7.20101 16.8309 7.50434 16.6868C7.86268 16.5159 8.20101 16.3068 8.51351 16.0626C8.77851 15.8559 9.01934 15.6151 9.50018 15.1343ZM17.3735 7.26093C17.988 6.6464 18.3333 5.81292 18.3333 4.94384C18.3333 4.07477 17.988 3.24129 17.3735 2.62676C16.759 2.01223 15.9255 1.66699 15.0564 1.66699C14.1874 1.66699 13.3539 2.01223 12.7393 2.62676L12.0002 3.36593L12.0318 3.45843C12.3961 4.50069 12.9922 5.44668 13.7752 6.22509C14.5769 7.03151 15.556 7.63935 16.6343 8.00009L17.3735 7.26093Z"
            fill={color}/>
    </svg>
);
